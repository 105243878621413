(function ($) {
  if(page_data['catalog-mpp'] && page_data['catalog-mpp'].categories) {
    var noStockStatusNumbers = [2, 3, 5, 7];
    var products = page_data['catalog-mpp'].categories[0].products;
    var productsData = {};
    products.forEach(function (product) {
      productsData[product.PRODUCT_ID] = product;
    });
    // Iterate over the products in the screen and verify if they're OOS
    $('.js-quickshop-show').each(function () {
      var self = $(this);
      var outOfStockCount = 0;
      var outOfStockDefaultSku = false;
      var productId = self.data('product-id');
      var productSkus = productsData[productId].skus;
      var defaultSku = productsData[productId].defaultSku;
      // Iterate over the skus for reviewing its inventory status
      if (productSkus) {
        productSkus.forEach(function (skuData) {
          var inventoryStatus = skuData.INVENTORY_STATUS;

          if (inventoryStatus && noStockStatusNumbers.includes(inventoryStatus)) {
            outOfStockCount++;
          }
        });
      }
      if (defaultSku && noStockStatusNumbers.includes(defaultSku.INVENTORY_STATUS)) {
        outOfStockDefaultSku = true;
      }
      if (!productSkus || outOfStockCount === productSkus.length || outOfStockDefaultSku) {
        self.html(site.translations.product.sold_out);
      }
    });
  }
})(jQuery);
